<template>
    <div class="user">
      <!-- 面包屑导航 -->
      <el-card>
        <!-- 头 -->
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input placeholder="请输入内容" v-model="getInfo.info">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>
          <el-col :span="8"> </el-col>
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="增加人数"
            placement="top-start"
          >
            <el-button  type="primary" @click="true">增加人数</el-button>
          </el-tooltip> -->
        </el-row>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          v-if="tableData"
        >
        <el-table-column
            align="center"
            prop="site_name"
            label="站点名称"
          >
          </el-table-column>
        <el-table-column
            align="center"
            prop="order_contact_name"
            label="用户名称"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="order_contact_phone"
            label="用户电话"
          >
          </el-table-column>
     
          <el-table-column
            align="center"
            prop="order_amount"
            label="订单金额"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="payment_amount"
            label="支付金额"
          >
 
          </el-table-column>

          <el-table-column
            align="center"
            prop="create_time"
            label="创建时间"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="order_number"
            label="订单号"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="payment_amount"
            label="支付状态"
          >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.order_status==-3">退款中</el-tag>
            <el-tag v-if="scope.row.order_status==-2">已作废</el-tag>
<el-tag type="success" v-if="scope.row.order_status==-1">已取消</el-tag>
<el-tag type="info" v-if="scope.row.order_status==0">待支付</el-tag>
<el-tag type="warning" v-if="scope.row.order_status==1">待确认</el-tag>
<el-tag type="danger" v-if="scope.row.order_status==2">已确认</el-tag> 
<el-tag type="danger" v-if="scope.row.order_status==3">已完成</el-tag> 
          </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="payment_amount"
            label="订单状态"
          >
          <template slot-scope="scope">
<el-tag type="info" v-if="scope.row.pay_status==0">待支付</el-tag>
<el-tag type="warning" v-if="scope.row.pay_status==1">已支付</el-tag>
<el-tag type="danger" v-if="scope.row.pay_status==2">已退款</el-tag> 
<el-tag type="danger" v-if="scope.row.pay_status==3">拒绝退款</el-tag> 
          </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="order_remarks"
            label="订单备注"
          >
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="查看订单"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-search"
                  @click="onLookOrder(scope.row.order_id)"
                ></el-button>
              </el-tooltip>
              <el-tooltip 
                class="item"
                effect="dark"
                content="确认订单"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-circle-check"
                  type="success"
                  @click="onOrderAffirm(scope.row.order_number)"
                ></el-button>
              </el-tooltip>
            <template v-if="scope.row.order_status==-3">
              <el-tooltip
                class="item"
                effect="dark"
                content="审核退款"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-s-check"
                  type="primary"
                  @click="onOrderRefound(scope.row)"
                ></el-button>
              </el-tooltip>
              </template>
              <el-tooltip
                class="item"
                effect="dark"
                content="删除订单"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-delete"
                  type="danger"
                  @click="onDeleteOrder(scope.row.order_id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @current-change="pageChange"
          @size-change="sizeChange"
          :page-sizes="[7, 15, 20]"
          layout="total, sizes, prev, pager, next"
          :page-size="this.getInfo.page_num"
          background
          :total="exp.num"
        >
        </el-pagination>
      </el-card>
       <!-- 增加场地服务标题 -->
      <el-dialog
        :visible.sync="dialogFormVisible"
        @close="userClose(form)"
        :append-to-body="true"
         
      >
      <div slot="title" v-if="updataFalg" class="dialog-footer" >
          <div class="title" >修改人数</div>
        </div>
         <div slot="title" v-else-if="lookFalg" class="dialog-footer" >
          <div class="title" >查看人数</div>
        </div>
        <div slot="title" v-else class="dialog-footer" >
          <div class="title" >增加人数</div>
        </div>
        <div class="diaBox">
        <el-form :model="form" ref="ruleForm"  :rules="rules">
                         <el-form-item label="人数" :label-width="formLabelWidth" prop="item">
                                   <el-input v-model="form.item"></el-input>
    </el-form-item> 
        </el-form>
         </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button v-if="addFlag" type="primary" @click="true"
            >添 加</el-button
          >
          <el-button v-else-if="lookFalg" type="primary" @click="dialogFormVisible = false"
            >确 认</el-button
          >
         <el-button v-else type="primary" @click="true"
            >修 改</el-button
          >
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  //这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
  //例如：import 《组件名称》 from '《组件路径》';
  import {PostOrderList,PostOrderRefund,PostOrderConfirms,PostOrderRefuseRefund} from "@/api/orderList/orderList"
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
          var validateItem = (rule, value, callback) => {
          if (this.form.item === '') {
            callback(new Error('请输入人数'));
          } else {
            callback();
          }
        };
      //这里存放数据
      return {
           rules: {
            item: [
              { validator: validateItem, trigger: 'blur' }
            ],         
          },
        //总条数
        exp: {
          count: Number,
          num: 0,
        },
        //当前页码
        getInfo: {
          //搜索框
          info: "",
          page_code: 1,
          page_num: 15,
        },
        form:{
          item:''
        },
        props:{
          children:'sub',
          value:'id',
          label:'name'
        },
        //表格数据
           tableData: [],
              token: {
          token: window.sessionStorage.getItem("token"),
        },
        formLabelWidth: "100px",
         imgParam: {
          path: "contract/template",
        },
          fileList: [],
          //控制弹窗--新增修改
        dialogFormVisible: false,
        addFlag:false,
        lookFalg:false,
        updataFalg:false,
      };
    },
    //监听属性 类似于data概念
    computed: {
          imgUrl() {
        return this.$store.state.imgUrl;
      },
    },
    //监控data中的数据变化
    watch: {
             dialogFormVisible(newVal, oldVal) {
        if (!newVal) {
          this.form = this.$options.data().form;
          this.updataFalg=false;
          this.lookFalg=false;
          this.addFlag=false;
        }
      },
    },
    //方法集合
    methods: {
      //确认订单
      onOrderAffirm(order_sn){
          var data={
            order_number:order_sn
          }
          this.PostOrderConfirms(data)
      },
      //订单退款
      onOrderRefound(item){
        var that=this;
        this.$confirm('是否同意退款', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '拒绝',
          type: 'warning'
        }).then(() => {
          console.log(item)
          var dataObj={
            order_number:item.order_number,
            refund_fee:item.order_amount,
            refund_desc:''
          }
          this.PostOrderRefund(dataObj);
          // this.$message({
          //   type: 'success',
          //   message: '退款成功!'
          // });
        }).catch(() => {
          var dataObj={
            order_number:item.order_number,
            refund_fee:item.order_amount,
            refund_desc:''
          }
          this.PostOrderRefuseRefund(dataObj);       
        });
      },
        onLookOrder(id){
          this.$router.push({
            name:'orderDetail',
            params:{
                id
            }
          })
        },
        //删除订单
        onDeleteOrder(id){

        },
      //修改每页数量
      sizeChange(value) {
        this.getInfo.page_code = value;
        var data = {
        page: this.getInfo.page_code,
        page_num: this.getInfo.page_num,
        order_status:10
      };
      this.tableData = [];
      this.PostOrderList(data);
       // this.getPeopleList(data);
      },
      //分页切换
      pageChange(value) {
        this.getInfo.page_code = value;
        var data = {
        page: this.getInfo.page_code,
        page_num: this.getInfo.page_num,
        order_status:10
      };
      this.tableData = [];
      this.PostOrderList(data);
      },
            exceed() {
        this.$message({
          message: "数量超出",
          type: "warning",
        });
      },
      //导入图片事件
      imgSuccess(response, file, fileList) {
          // console.log(response)
        this.form.url = response.data.url;
      },
  
      //移除图片的事件
      imgRemove(file, fileList) {
        this.form.url = "";
      },
      /**-----------------网络请求生命周期函数--------------------- */
      //订单列表
      async PostOrderList(data){
        let res=await PostOrderList(data);
        this.tableData = res.data.result.data;
          this.getInfo = {
            page_code: res.data.result.last_page,
            page_num: res.data.result.per_page,
          };
          // this.form.class
          this.exp.num = res.data.result.total;
      },
      //确认订单
      async PostOrderConfirms(data){
           let res=await PostOrderConfirms(data);
          //  console.log(res);
           if(res.data.status==1){
            var dataObj = {
        page: this.getInfo.page_code,
        page_num: this.getInfo.page_num,
        order_status:10
      };
      this.PostOrderList(dataObj);
            this.$message({
          message: res.data.message,
          type: 'success'
        });
           }else{
            this.$message.error(res.data.message);
           }
     
      },
      
      //订单同意退款
      async PostOrderRefund(data){
           let res=await PostOrderRefund(data);
           if(res.data.status==1){
            var dataObj = {
        page: this.getInfo.page_code,
        page_num: this.getInfo.page_num,
        order_status:10
      };
      this.PostOrderList(dataObj);
            this.$message({
          message: res.data.message,
          type: 'success'
        });
           }else{
            this.$message.error(res.data.message);
           }
     
      },
      //订单拒绝退款
      async PostOrderRefuseRefund(data){
           let res=await PostOrderRefuseRefund(data);
           if(res.data.status==1){
            var dataObj = {
        page: this.getInfo.page_code,
        page_num: this.getInfo.page_num,
        order_status:10
      };
      this.PostOrderList(dataObj);
            this.$message({
          message: res.data.message,
          type: 'success'
        });
           }else{
            this.$message.error(res.data.message);
           }
     
      },
    },
    beforeCreate() {}, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
      var data = {
        page: this.getInfo.page_code,
        page_num: this.getInfo.page_num,
        order_status:10
      };
      this.PostOrderList(data);
    },
  
    beforeMount() {}, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
  };
  </script>
  
  <style scoped>
  .page {
    margin-top: 20px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .set-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .set-page-left {
    color: #0000006d;
    font-size: 14px;
    font-weight: 400;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .diaBox {
    display: flex;
    justify-content: center;
  }
  .imgUrl {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 250px;
    height: 150px;
    overflow: hidden;
    text-align: center;
  }
  </style>
