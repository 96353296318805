import {post,get} from '@/utils/request'

//订单列表
export function PostOrderList(data) {
  return post('adminapi/order_list',data)
}
//确认订单
export function PostOrderConfirms(data) {
  return post('adminapi/order_confirms',data)
}

//订单同意退款
export function PostOrderRefund(data) {
  return post('adminapi/order_refund',data)
}
//订单拒绝退款
export function PostOrderRefuseRefund(data) {
  return post('adminapi/order_refuse_refund',data)
}
